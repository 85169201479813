import * as React from "react";
//import styled from "styled-components";

// styles

// markup
export default function Index() {
  return (
    <div>
        <a href="/">home</a>
    </div>
  );
}